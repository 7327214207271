import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';

const TOKEN_HEADER_KEY = 'Authorization';
const USER_ID = 'userid';
const BR_ID = 'brid';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private token: TokenStorageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // return next.handle(request);
    let authReq = request;

    const token = this.token.getToken();
    const userid = this.token.getSession(USER_ID);
    const brid = this.token.getSession(BR_ID);
    
    if (token != null && userid != null && brid != null) {
      authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token).set(USER_ID, userid).set(BR_ID, brid) });
    }
    return next.handle(authReq);
  }
}
export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];