<div class="header">
    <mat-icon>warning</mat-icon>
    <h6><b>Error</b></h6>
</div>
<mat-divider></mat-divider>
<div class="content">
    <table>
        <tr>
            <td><b>Error message</b></td>
            <td><b>:</b></td>
            <td>{{_error?.message}}</td>
        </tr>
        <tr>
            <td><b>Error code</b></td>
            <td><b>:</b></td>
            <td>{{_error?.status}}</td>
        </tr>
    </table>
</div>
<mat-divider></mat-divider>
<p style="color: red;">Please contact administrator.</p>