import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private _cookie: CookieService, private _router: Router) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this._cookie.get('userid') || !this._cookie.get('opendate') || !this._cookie.get('brid') || !this._cookie.get('usercode')) {
      this._router.navigate(['/auth/login']);
    }
    else return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this._cookie.get('userid') || !this._cookie.get('opendate') || !this._cookie.get('brid') || !this._cookie.get('usercode')) {
      this._router.navigate(['/auth/login']);
    }
    else return true;
  }
  
}
