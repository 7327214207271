export const environment = {
  production: true,
  _customerAPI: 'http://www.fin.guruvaramnidhi.com:3000/customer',
  _accountsAPI: 'http://www.fin.guruvaramnidhi.com:3000/accounts',
  _searchAPI: 'http://www.fin.guruvaramnidhi.com:3000/search',
  _reportsAPI: 'http://www.fin.guruvaramnidhi.com:3000/reports',
  _verifyAPI: 'http://www.fin.guruvaramnidhi.com:3000/verification',
  _viewsAPI: 'http://www.fin.guruvaramnidhi.com:3000/views',
  _ledgerAPI: 'http://www.fin.guruvaramnidhi.com:3000/ledgers',
  _authAPI: 'http://www.fin.guruvaramnidhi.com:3000/auth',
  _utilAPI: 'http://www.fin.guruvaramnidhi.com:3000/util',
  _settingsAPI: 'http://www.fin.guruvaramnidhi.com:3000/settings',
  _accEntryAPI: 'http://www.fin.guruvaramnidhi.com:3000/accentry',
  _interestAPI: 'http://www.fin.guruvaramnidhi.com:3000/interest',
  _managerAPI: 'http://www.fin.guruvaramnidhi.com:3000/interest',
  __printAPI: 'http://www.fin.guruvaramnidhi.com:5000/jobs',
  __resourceAPI: 'http://www.fin.guruvaramnidhi.com:5100',
  _logAPI: 'http://www.fin.guruvaramnidhi.com:3000/log',
  _hofAPI: 'http://www.fin.guruvaramnidhi.com:3000/hof',
  _slipsAPI: 'http://www.fin.guruvaramnidhi.com:5000/slips',
  tasksAPI: 'http://www.fin.guruvaramnidhi.com:1234/tasks',
  _postingAPI: 'http://www.fin.guruvaramnidhi.com:3000/posting',
  printProtocol: 'pp20',
  fastPrint: 'fprint',
  _editAPI: 'http://www.fin.guruvaramnidhi.com:3000/edit',
  _legalAPI: 'http://www.fin.guruvaramnidhi.com:3000/legal',
 _assetsPath: 'http://www.fin.guruvaramnidhi.com:3000/assets',
  _payrollPath: 'http://www.fin.guruvaramnidhi.com:3000/payroll'
};

// export const environment = {
//   production: true,
//   _customerAPI: 'http://localhost:3000/customer',
//   _accountsAPI: 'http://localhost:3000/accounts',
//   _searchAPI: 'http://localhost:3000/search',
//   _reportsAPI: 'http://localhost:3000/reports',
//   _verifyAPI: 'http://localhost:3000/verification',
//   _viewsAPI: 'http://localhost:3000/views',
//   _ledgerAPI: 'http://localhost:3000/ledgers',
//   _authAPI: 'http://localhost:3000/auth',
//   _utilAPI: 'http://localhost:3000/util',
//   _settingsAPI: 'http://localhost:3000/settings',
//   _accEntryAPI: 'http://localhost:3000/accentry',
//   _interestAPI: 'http://localhost:3000/interest',
//   _managerAPI: 'http://localhost:3000/interest',
//   __printAPI: 'http://localhost:5000/jobs',
//   __resourceAPI: 'http://localhost:5100',
//   _logAPI: 'http://localhost:3000/log',
//   _hofAPI: 'http://localhost:3000/hof',
//   _slipsAPI: 'http://localhost:5000/slips',
//   tasksAPI: 'http://localhost:1234/tasks',
//   _postingAPI: 'http://localhost:3000/posting',
//   printProtocol: 'pp20',
//   fastPrint: 'fprint',
//   _editAPI: 'http://localhost:3000/edit',
//   _legalAPI: 'http://localhost:3000/legal'
   
// };

// export const environment = {
//   production: true,
//   _customerAPI: 'http://192.168.1.178:3000/customer',
//   _accountsAPI: 'http://192.168.1.178:3000/accounts',
//   _searchAPI: 'http://192.168.1.178:3000/search',
//   _reportsAPI: 'http://192.168.1.178:3000/reports',
//   _verifyAPI: 'http://192.168.1.178:3000/verification',
//   _viewsAPI: 'http://192.168.1.178:3000/views',
//   _ledgerAPI: 'http://192.168.1.178:3000/ledgers',
//   _authAPI: 'http://192.168.1.178:3000/auth',
//   _utilAPI: 'http://192.168.1.178:3000/util',
//   _settingsAPI: 'http://192.168.1.178:3000/settings',
//   _accEntryAPI: 'http://192.168.1.178:3000/accentry',
//   _interestAPI: 'http://192.168.1.178:3000/interest',
//   _managerAPI: 'http://192.168.1.178:3000/interest',
//   __printAPI: 'http://192.168.1.178:5000/jobs',
//   __resourceAPI: 'http://192.168.1.178:5100',
//   _logAPI: 'http://192.168.1.178:3000/log',
//   _hofAPI: 'http://192.168.1.178:3000/hof',
//   _slipsAPI: 'http://192.168.1.178:5000/slips',
//   tasksAPI: 'http://192.168.1.178:1234/tasks',
//   _postingAPI: 'http://192.168.1.178:3000/posting',
//   printProtocol: 'pp20',
//   fastPrint: 'fprint',
//   _editAPI: 'http://192.168.1.178:3000/edit',
//   _legalAPI: 'http://192.168.1.178:3000/legal',
//   _assetsPath: 'http://192.168.1.178:3000/assets',
//   _payrollPath: 'http://192.168.1.178:3000/payroll'
// };

// export const environment = {
//   production: true,
//   _customerAPI: 'http://localhost:3000/customer',
//   _accountsAPI: 'http://localhost:3000/accounts',
//   _searchAPI: 'http://localhost:3000/search',
//   _reportsAPI: 'http://localhost:3000/reports',
//   _verifyAPI: 'http://localhost:3000/verification',
//   _viewsAPI: 'http://localhost:3000/views',
//   _ledgerAPI: 'http://localhost:3000/ledgers',
//   _authAPI: 'http://localhost:3000/auth',
//   _utilAPI: 'http://localhost:3000/util',
//   _settingsAPI: 'http://localhost:3000/settings',
//   _accEntryAPI: 'http://localhost:3000/accentry',
//   _interestAPI: 'http://localhost:3000/interest',
//   _managerAPI: 'http://localhost:3000/interest',
//   __printAPI: 'http://localhost:5000/jobs',
//   __resourceAPI: 'http://localhost:5100',
//   _logAPI: 'http://localhost:3000/log',
//   _hofAPI: 'http://localhost:3000/hof',
//   _slipsAPI: 'http://localhost:5000/slips',
//   tasksAPI: 'http://localhost:1234/tasks',
//   _postingAPI: 'http://localhost:3000/posting',
//   printProtocol: 'pp20',
//   fastPrint: 'fprint',
//   _editAPI: 'http://localhost:3000/edit',
//   _legalAPI: 'http://localhost:3000/legal'
// };