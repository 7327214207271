export const PAYMENT_MODES = [
    { label: 'Cash', value: 'C' },
    { label: 'Transfer', value: 'T' },
    // { label: 'Bank', value: 'B' }
]

export const AC_TYPES = [
    { label: 'Single', value: 'S' },
    // { label: 'Multiple', value: 'multi' }
]
const JOINT_OPERATING_TYPES = [
    { label: 'Both', value: 'Both' },
    { label: 'Either or Survivor', value: 'Either or Survivor' },
    { label: 'Former or Survivor', value: 'Former or Survivor' },
    //{ label: 'Type 4', value: 'value_4' },
]
const COMPANY_OPERATING_TYPES = [
    { label: 'Both', value: 'Both' },
    { label: 'Either or Survivor', value: 'Either or Survivor' },
    { label: 'Former or Survivor', value: 'Former or Survivor' },
    //{ label: 'Type - 4', value: 'value_4' },
]
export const AC_TRANSFER = [
    { label: 'Single', value: 'S', types: null },
    { label: 'Joint', value: 'J', types: JOINT_OPERATING_TYPES },
    { label: 'Company', value: 'C', types: COMPANY_OPERATING_TYPES }
]
export const DURATION_TYPES = [
    { label: 'Year(s)', value: 'Y' },
    { label: 'Month(s)', value: 'M' },
    { label: 'Week(s)', value: 'W' },
    { label: 'Day(s)', value: 'D' }
]
export const TRANSACTION_TYPES = [
    { label: 'Receipt', value: 'R' },
    { label: 'Payment', value: 'P' }
]

export const MONTHS_LIST = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 }
]

export class TRANSACTION_LIMIT {
    public static WITHDRAW_LIMIT = 50000;
    public static DEPOSIT_LIMIT = 200000;
    public static GOLDWITHDRAW_LIMIT = 200000;
}

export const INTEREST_CALCULATED_ON = [
    { label: 'DUE AMOUNT', value: 'D' },
    { label: 'FULL AMOUNT', value: 'F' }
]

export const INTEREST_METHODS = [
    { label: 'DAILY', value: 'D' },
    { label: 'MONTHLY', value: 'M' },
    { label: 'HALF MONTHLY', value: 'HM' },
    { label: 'YEARLY', value: 'Y' }
]

export const PURPOSE_TYPES = [
    { label: 'Agricultural', value: 'Agricultural' },
    { label: 'Non-Agricultural', value: 'Non-Agricultural' }
]

export const COMMON_TRANS_BAR = [
    { label: 'New', value: 'new', headOfAccount: true, AccSearch: true, CustomerSearch: false, typeOfAccount: true },
    { label: 'Receipt', value: 'receipts', headOfAccount: true, AccSearch: true, CustomerSearch: false, typeOfAccount: true },
    { label: 'Payment', value: 'payments', headOfAccount: true, AccSearch: true, CustomerSearch: false, typeOfAccount: true },
    // { label: 'Renewal', value: 'renewal', headOfAccount: true, AccSearch: true, CustomerSearch: true },
    // { label: 'Transfer', value: 'transfer', headOfAccount: true, AccSearch: true, CustomerSearch: true },
    // { label: 'Other Charges', value: 'otherCharges', headOfAccount: true, AccSearch: true, CustomerSearch: true },
    // { label: 'Payable', value: 'payable', headOfAccount: true, AccSearch: true, CustomerSearch: true },
    // { label: 'Standing', value: 'standing', headOfAccount: true, AccSearch: true, CustomerSearch: true },
    // { label: 'View', value: 'view', headOfAccount: true, AccSearch: true, CustomerSearch: true }
]