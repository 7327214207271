import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms'
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth/auth.service';
import { SharedService } from '../shared/shared.service';
import { DASHBOARD_TYPES } from '../_models/dashboardTypes';
@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private _router: Router, private _uiService: SharedService, private _cookieService: CookieService, private _auth: AuthService) { }

  static isDecimal(): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } | null => {
      let val: string = control.value;

      if (control.pristine) return null

      const regex = new RegExp("^([1-9]+\\d*([.]\\d+)?)$|^(0[.]\\d*[1-9]+)$|^0$")
      const status = regex.test(val)

      return { 'isDecimal': status };
    }
  }

  async checkDashPrevilegesGuard(dashboard) {
    let response = await this._auth.getDefaultDashboardGuard(this._cookieService.get('userid'))
    if (response['status'] && response['result'].length > 0) {
      let prevData = response['result'];
      prevData.forEach(element => {
        if (element.dashboard == dashboard) {
          return true
        }
        else return false
      });
    }
    else return false
  }

  async gotoDefaultDashboardGuard() {
    var DFLAG = false
    let response = await this._auth.getDefaultDashboardGuard(this._cookieService.get('userid'))
    if (response['status'] && response['result'].length > 0) {
      let prevData = response['result'];
      DASHBOARD_TYPES.forEach(item => {
        prevData.forEach(element => {
          if (element.dashboard == item.previlege && element.defaultflag == 'Y') {
            this._router.navigate([item.path]);
            DFLAG = true
          }
        });
      });
      if (!DFLAG) this._uiService.openSnackBar('No default dashboard found', 'OK')
    }
  }
}
