import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  fetchBranchWithUserid(arg0: { userid: number; }) {
    throw new Error('Method not implemented.');
  }

  private generalLedgerSource = new BehaviorSubject<any>({ isValid: false, data: null });
  generalLedger$ = this.generalLedgerSource.asObservable();

  setGeneralLedger(formValid, formData) {
    this.generalLedgerSource.next({ isValid: formValid, data: formData });
  }

  constructor(private _http: HttpClient) { }

  getBranches() {
    return this._http.get(`${environment._utilAPI}/branches`);
  }

  getHeadofAccounts() {
    return this._http.get(`${environment._utilAPI}/hoas`);
  }
  gettypes(module) {
    const types = {
      module: module
    }
    return this._http.post(`${environment._accountsAPI}/Subtype`, types);
  }

  isHeadOfficeOpen(opendate) {
    return this._http.get(`${environment._utilAPI}/hofstat/${opendate}`);
  }

  getBankDetails(brid) {
    return this._http.post(environment._authAPI + '/fetchBankDetails', { brid: brid })
  }

  getUserLinks(userid) {
    return this._http.post(`${environment._authAPI}/user-links`, { _id: userid });
  }

  getUserByBranch(brid, type) {
    return this._http.post(`${environment._hofAPI}/b-users`, { brid, type })
  }

  getSectionData(sectionName = null) {
    return this._http.post(`${environment._hofAPI}/sections`, { sectionName }).toPromise()
  }

  /* Source for accessing dashboard previlege details */
  private _dashPrevSource = new BehaviorSubject<any>({ isValid: false, dashPrevData: null });
  dashPrevData$ = this._dashPrevSource.asObservable();

  setDashPrevilegeDetails(formValid, dashPrevData) {
    this._dashPrevSource.next({ isValid: formValid, dashPrevData: dashPrevData })
  }
}
