import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ONES, TENS, TEENS } from '../_models/amountWordsSet';
import { TRANSACTION_LIMIT } from "../_models/transactions";
import * as XLSX from 'xlsx';
declare function offsetMonth(date: Date, offset: number): any;
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  bankName: string;
  addr1: string;
  addr2: string;
  regNo: number;
  place: string;
  addr: string;
  regPlace: string;
  branch: string;

  ones = ONES;
  tens = TENS;
  teens = TEENS;
  DEPOSIT_LIMIT = TRANSACTION_LIMIT.DEPOSIT_LIMIT;
  WITHDRAW_LIMIT = TRANSACTION_LIMIT.WITHDRAW_LIMIT;
  GOLDWITHDRAW_LIMIT = TRANSACTION_LIMIT.GOLDWITHDRAW_LIMIT;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) { }

  scroll(el: HTMLElement, behaviour: any = "smooth", block: any = "start", inline: any = "nearest") {
    el.scrollIntoView({ behavior: behaviour, block: block, inline: inline })
  }

  formatDate(date): string {
    const _date = new Date(date);
    const day = _date.getDate();
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    var corrected_month = month.toString();
    if (month.toString().length == 1) corrected_month = `0${month}`;
    return `${year}-${corrected_month}-${day}`;
  }

  formatTime(date: Date): string {
    const _date = new Date(date);
    const hours = _date.getHours()
    const minutes = _date.getMinutes();
    const seconds = _date.getSeconds();
    return `${hours}:${minutes}:${seconds}`;
  }

  toDateTimestamp(date: Date): string {
    const dateStamp = this.formatDate(date);
    const timeStamp = this.formatTime(date);
    return `${dateStamp} ${timeStamp}`
  }
  calculateDays(fromDate, toDate): number {
    const FromDate = new Date(fromDate);
    const ToDate = new Date(toDate);
    const difference = ToDate.getTime() - FromDate.getTime();
    const days = Math.round((difference / (1000 * 60 * 60 * 24)));
    return days;
  }
  getCollectionAgent(agentType) {
    return this.http.post(environment._settingsAPI + '/getCollectionAgent', { agentType: agentType })
  }
  addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  addDays(date: Date, days: number): Date {
    // console.log(date.getDate())

    date.setDate(date.getDate() + days);
    return date;
  }

  monthDiff(fromDate, toDate) {
    var months;
    const d1 = (new Date(fromDate))
    const d2 = (new Date(toDate))
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    months = d1.getDate() > d2.getDate() ? months - 1 : months;
    return months <= 0 ? 0 : months;
  }

  dateDiff(fromDate, toDate, type: string) {
    var diff = 0;
    const d1 = new Date(fromDate);
    const d2 = new Date(toDate);
    if (type.toLowerCase() == 'd') { diff = this.calculateDays(d1, d2); }
    if (type.toLowerCase() == 'w') { diff = Math.ceil(this.calculateDays(d1, d2) / 7); }
    if (type.toLowerCase() == 'y') { diff = d2.getFullYear() - d1.getFullYear(); }
    if (type.toLowerCase() == 'm') { diff = this.monthDiff(d1, d2); }

    return diff;
  }
  offsetDateBy(date, offset, type: string): Date {
    const dateObj = new Date(date);
    let offsetDate = new Date(date);
    if (type.toLowerCase() == 'd') { offsetDate.setDate(dateObj.getDate() + offset) }
    // if (type.toLowerCase() == 'm') { offsetDate.setMonth(dateObj.getMonth() + offset) }
    if (type.toLowerCase() == 'm') { offsetDate = offsetMonth(dateObj, offset) }
    if (type.toLowerCase() == 'y') { offsetDate.setFullYear(dateObj.getFullYear() + offset) }

    return offsetDate;
  }
  getDaysArray(start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt).getDate());
    }
    // console.log(dt)
    return arr;
  };
  getAge(dob) {
    var timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
    const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    return age;
  }
  refreshPage() {
    window.location.reload();
  }
  addActivityLog(userid, branchid, remarks, previousValue, currentValue) {
    let params = {
      userid: userid,
      branchid: branchid,
      remarks: remarks,
      previousValue: previousValue,
      currentValue: currentValue
    }
    // console.log(params)
    return this.http.post(environment._logAPI + '/addActivityLog', params)
  }
  calculateAge(dob, ason) {
    var age = 0
    let Senior: boolean = false;
    if (dob) {
      var timeDiff = Math.abs(new Date(ason).getTime() - new Date(dob).getTime());
      age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    }
    else {
      age = 0;
    }
    if (age >= 60) {
      Senior = true
    }

    return { age: age, Senior: Senior }
  }

  convertAmttoWords(amount) {
    return (`RUPEES ${this.convert(amount)} ONLY`).toUpperCase()
  }

  convert(amount) {
    if (amount == 0) return "zero";
    else return this.convert_lakhs(amount);
  }

  convert_lakhs(num) {
    if (num >= 100000) {
      return this.convert_lakhs(Math.floor(num / 100000)) + " lakhs " + this.convert_thousands(num % 100000);
    } else {
      return this.convert_thousands(num);
    }
  }

  convert_thousands(num) {
    if (num >= 1000) {
      return this.convert_hundreds(Math.floor(num / 1000)) + " thousand " + this.convert_hundreds(num % 1000);
    } else {
      return this.convert_hundreds(num);
    }
  }

  convert_hundreds(num) {
    if (num > 99) {
      return this.ones[Math.floor(num / 100)] + " hundred " + this.convert_tens(num % 100);
    } else {
      return this.convert_tens(num);
    }
  }

  convert_tens(num) {
    if (num < 10) return this.ones[num];
    else if (num >= 10 && num < 20) return this.teens[num - 10];
    else {
      return this.tens[Math.floor(num / 10)] + " " + this.ones[num % 10];
    }
  }

  checkTransactionLimit(amount, type) {
    switch (type) {
      case 'DEPOSIT':
        if (amount > this.DEPOSIT_LIMIT) {
          return true
        }
        else return false
      // break;
      case 'WITHDRAW':
        if (amount > this.WITHDRAW_LIMIT) {
          return true
        }
        else return false
        case 'GOLDWITHDRAW':
          if (amount > this.GOLDWITHDRAW_LIMIT) {
            return true
          }
          else return false
      // break;
      default:
        break;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ExportToExcel(sheetName, fileName, nativeElement) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(nativeElement, { dateNF: 'mm/dd/yyyy', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* save to file */
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  checkMemberShare(memno) {
    let params = { memno: memno }
    return this.http.post(environment._utilAPI + '/getMemberShare', params).toPromise()
  }

  fetchLastTransaction(module, accid) {
    let params = {
      module: module,
      accid: accid
    }

    return this.http.post(`${environment._accountsAPI}/fetchLastTransaction`, params).toPromise()
  }

  groupBy(groupingColumn: string[], data: any[], reducedGroups?: any[],firstName_optional='',groupSummary_optional='',ReportFooterTotal_optional=false) {
    if (groupingColumn == null || groupingColumn == undefined || groupingColumn.length == 0) {
      return data
    }

    let collapsedGroups = reducedGroups;
    if (!reducedGroups) collapsedGroups = [];

    let columnLength = groupingColumn.length
    let currentGroup = []

    const customReducer = (accumulator, currentValue) => {

      for (let i = 0; i < columnLength; i++) {
        currentGroup[i] = currentValue[groupingColumn[i]]
      }

      switch (columnLength) {
        case 1:
          if (!accumulator[currentGroup[0]]) {
            if (firstName_optional==='') {
              accumulator[currentGroup[0]] = [{
                firstName: `${String(currentValue[groupingColumn[0]]).toUpperCase()}`,
                value: currentValue[groupingColumn[0]],
                isFirstGroup: true,
                reduced: collapsedGroups.some((group) => group.value == currentValue[groupingColumn[0]]),
              }];
            }
            else{
              accumulator[currentGroup[0]] = [{
                firstName_optional: `${String(currentValue[groupingColumn[0]]).toUpperCase()}`,
                 value_optional: currentValue[groupingColumn[0]],
                isFirstGroup_optional: true,
                reduced_optional: collapsedGroups.some((group) => group.value == currentValue[groupingColumn[0]]),
              }];
            }
            
          }
          accumulator[currentGroup[0]].push(currentValue);
          return accumulator
        case 2:
          if (!accumulator[currentGroup[0]]) {

            accumulator[currentGroup[0]] = [{
              firstName: `${String(currentValue[groupingColumn[0]]).toUpperCase()}`,
              secondName: `${String(currentValue[groupingColumn[1]]).toUpperCase()}`,
              value: currentValue[groupingColumn[0]],
              isFirstGroup: true,
              reduced: collapsedGroups.some((group) => group.value == currentValue[groupingColumn[0]]),
            }];
          }

          if (!accumulator[currentGroup[1]]) {

            accumulator[currentGroup[1]] = [{
              secondName: `${String(currentValue[groupingColumn[1]]).toUpperCase()}`,
              value: currentValue[groupingColumn[1]],
              isSecondGroup: true,
              reduced: collapsedGroups.some((group) => group.value == currentValue[groupingColumn[1]]),
            }];
          }
          accumulator[currentGroup[1]].push(currentValue);
          return accumulator
        case 3:
          if (!accumulator[currentGroup[0]]) {

            accumulator[currentGroup[0]] = [{
              firstName: `${String(currentValue[groupingColumn[0]]).toUpperCase()}`,
              secondName: `${String(currentValue[groupingColumn[1]]).toUpperCase()}`,
              thirdName: `${String(currentValue[groupingColumn[2]]).toUpperCase()}`,
              value: currentValue[groupingColumn[0]],
              isFirstGroup: true,
              reduced: collapsedGroups.some((group) => group.value == currentValue[groupingColumn[0]]),
            }];
          }

          if (!accumulator[currentGroup[1]]) {

            accumulator[currentGroup[1]] = [{
              secondName: `${String(currentValue[groupingColumn[1]]).toUpperCase()}`,
              thirdName: `${String(currentValue[groupingColumn[2]]).toUpperCase()}`,
              value: currentValue[groupingColumn[1]],
              isSecondGroup: true,
              reduced: collapsedGroups.some((group) => group.value == currentValue[groupingColumn[1]]),
            }];
          }
          if (!accumulator[currentGroup[2]]) {

            accumulator[currentGroup[2]] = [{
              thirdName: `${String(currentValue[groupingColumn[2]]).toUpperCase()}`,
              value: currentValue[groupingColumn[2]],
              isThirdGroup: true,
              reduced: collapsedGroups.some((group) => group.value == currentValue[groupingColumn[2]]),
            }];
          }
          accumulator[currentGroup[2]].push(currentValue);
          return accumulator
        default:
          break;
      }

    }

    let groups = data.reduce(customReducer, {});
    let groupArray = Object.keys(groups).map(key => groups[key]);
    let flatList = groupArray.reduce((a, c) => { return a.concat(c); }, []);
    let newFlatList = flatList;
    let groupfooterList: any = [];
    var sumfield1:number=0
    var sumfield2: number = 0
    var sumfield3: number = 0
    var sumfield4: number = 0
    var sumfield5: number = 0
    var sumfield6: number = 0
    var sumfield7: number = 0
    var sumfield8: number = 0
    var sumfield9: number = 0
    var sumfield10: number = 0
    var sumfield11: number = 0
    var sumfield12: number = 0
    var sumfield13: number = 0
    var sumfield14: number = 0
    var sumfield15: number = 0
    var sumfield16: number = 0
    var sumfield17: number = 0
    newFlatList = []
    groupfooterList = []
    var arrayLen = flatList.length
    var count = 0
    flatList.forEach(rawLine => {

      if (rawLine.isFirstGroup) {
        sumfield1=sumfield2 = sumfield3 = sumfield4 =sumfield5 =sumfield6 =sumfield7=sumfield8 =sumfield9 =sumfield10 =sumfield11 =sumfield12 =sumfield13 =sumfield14 =sumfield15 =sumfield16 =sumfield17 =0
        
       
        if (groupfooterList.length > 0) newFlatList.push(groupfooterList[0])
        newFlatList.push(rawLine)
        groupfooterList = []
        count++;
      }
      else {
        count++;
        groupfooterList = []
        // totalLoanAmount+=Number(rawLine.Amount)
        // totalPrincipal += Number(rawLine.Principal)
        // totalPenal += Number(rawLine.Penal)
        // totalOthers += Number(rawLine.Others)
        // totalAmount += Number(rawLine.Total)
        sumfield1 += Number(rawLine.Interest)
        newFlatList.push(rawLine)
        if (count == arrayLen && ReportFooterTotal_optional ) {
          groupfooterList.push({ 'sumfield1':sumfield1, 'sumfield2': sumfield2, 'sumfield3': sumfield3, 'sumfield4': sumfield4, 'sumfield5': sumfield5, 'sumfield6': sumfield6, 'sumfield7': sumfield7, 'sumfield8': sumfield8,'sumfield9': sumfield9,'sumfield10':sumfield10, 'sumfield11':sumfield11,'sumfield12':sumfield12,isGroupFooter: true, isLastFooter: true })
        }
        else {
          groupfooterList.push({  'sumfield1':sumfield1, 'sumfield2': sumfield2, 'sumfield3': sumfield3, 'sumfield4': sumfield4, 'sumfield5': sumfield5, 'sumfield6': sumfield6, 'sumfield7': sumfield7, 'sumfield8': sumfield8,'sumfield9': sumfield9,'sumfield10':sumfield10, 'sumfield11':sumfield11,'sumfield12':sumfield12, isGroupFooter: true, isLastFooter: false })
        }
      }

    });
    newFlatList.push(groupfooterList[0])/* For including last row of group total */
    return newFlatList.filter((rawLine) => {
      return rawLine.isFirstGroup ||
        collapsedGroups.every((group) => rawLine[currentGroup[0]] != group.value);
    });
  }
  
  async setbankandBranch(brid) {
    const res = await this.fetchBankAndBranch(brid)
    if (res['status']) {
      this.bankName = res['result'][0].bankName;
      this.addr1 = res['result'][0].bankAdd1;
      this.addr2 = res['result'][0].bankAdd2;
      this.addr = this.addr1 + ', ' + this.addr2;
      this.regNo = res['result'][0].bankRegNo;
      this.place = res['result'][0].bankCity;
      this.regPlace = this.regNo + ', ' + this.place;
      this.branch = res['result'][0].branchName;
    }
    else {

    }
    return [this.bankName, this.addr, this.regPlace, this.branch]
  }
  fetchBankAndBranch(brid) {
    const param = {
      brid: brid
    }
    return this.http.post(environment._authAPI + '/fetchBankAndBranch', param).toPromise()
  }

}
