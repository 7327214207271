export const KEYBOARD_SHORTCUTS = [
    { hint: 'Keyboard Shortcuts', command: 'CTRL + H', remarks: '' },
    { hint: 'New Account', command: 'CTRL + A', remarks: '' },
    { hint: 'New Receipt', command: 'CTRL + R', remarks: '' },
    { hint: 'New Payment', command: 'CTRL + P', remarks: '' },
    { hint: 'Renewals', command: 'CTRL + E', remarks: '' },
    { hint: 'Other Charges', command: 'CTRL + O', remarks: '' },
    { hint: 'FDSB Adjustment', command: 'ALT + F', remarks: '' },
    { hint: 'SBRD Adjustment', command: 'ALT + S', remarks: '' },
    { hint: 'All Reports', command: 'ALT + R', remarks: '' },
    { hint: 'Detailed Day-book', command: 'ALT + D', remarks: '' },
    { hint: 'Customer Register', command: 'ALT + C', remarks: '' },
    { hint: 'Customer Search', command: 'CTRL + F', remarks: '(Only in Customer Register)' },
    { hint: 'Advanced Customer Search', command: 'CTRL + SHIFT + F', remarks: '(Only in Customer Register)' },
    { hint: 'Refresh', command: 'F5', remarks: '(Only if required)' },
]