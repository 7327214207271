export const DASHBOARD_TYPES = [
    { previlege: 'Edit Dashboard', path: '/edit/dashboard', icon: 'edit' },
    { previlege: 'Verification Dashboard', path: '/verification/dashboard', icon: 'dashboard' },
    { previlege: 'Opening Dashboard', path: '/edit/openings', icon: 'add_circle' },
    { previlege: 'Reports Dashboard', path: '/reports/dashboard', icon: 'description' },
    { previlege: 'Headoffice Dashboard', path: '/head-office/dashboard', icon: 'account_balance' },
    { previlege: 'Interest Dashboard', path: '/interest/dashboard', icon: 'swap_horiz' },
    { previlege: 'Staff Dashboard', path: '/staff/dashboard', icon: 'home' },
    { previlege: 'Manager Dashboard', path: '/manager/dashboard', icon: 'dashboard' },
    { previlege: 'Legal Actions Dashboard', path: '/legal-actions/dashboard', icon: 'gavel' }
]

export const DASHBOARD_COLUMNS = [
    { columns: 4 }
]

export const STAFF_DASHBOARD = [
    { action: 'Approve Membership', path: '/head-office/share/approval', icon: 'how_to_reg' },
    { action: 'Ledgers', path: '/reports/dashboard/ledgers', icon: 'receipt' },
    { action: 'Manager Passing', path: '/verification/dashboard/manager', icon: 'supervisor_account' },
    { action: 'Cashier Passing', path: '/verification/dashboard/cashier', icon: 'payments' },
]

export const EDIT_DASHBOARD = [
    { action: 'Edit Transactions', path: '/staff/dashboard/transactions/edit-transaction', icon: 'edit_note' },
    { action: 'Edit Standing Instruction', path: '/edit/dashboard/si-instruction', icon: 'edit_note' },
    { action: 'Edit Documents', path: '/edit/dashboard/documents', icon: 'edit_note' },
    { action: 'Change Account Holder', path: '/edit/dashboard/account-holder', icon: 'manage_accounts' },
    { action: 'Edit Nominee', path: '/edit/dashboard/nominee', icon: 'manage_accounts' },
    { action: 'Edit Member Sureties', path: '/edit/dashboard/member-sureties', icon: 'manage_accounts' },
    { action: 'Edit Other Securities', path: '/edit/dashboard/other-securities', icon: 'manage_accounts' },
    { action: 'Edit Dep Loan Sureties', path: '/edit/dashboard/dep-loan-sureties', icon: 'manage_accounts' },
    { action: 'Edit Neft', path: '/edit/dashboard/neft', icon: 'edit_note' },
    { action: 'Edit Previleges', path: '/staff/dashboard/previleges/new', icon: 'edit' },
    { action: 'Edit Narration Data', path: '/edit/dashboard/narration-data', icon: 'edit' },
    { action: 'Edit Opening', path: '/edit/openings', icon: 'edit' },
    { action: 'Edit Joint Holder', path: '/edit/dashboard/joint-holder', icon: 'edit' },
]

export const OPENING_DASHBOARD = [
    { action: 'Savings', path: '/edit/openings/sb', icon: 'edit' },
    { action: 'Gold Loan', path: '/edit/openings/gl', icon: 'edit' },
    { action: 'Loan', path: '/edit/openings/loan', icon: 'edit' },
    { action: 'RD', path: '/edit/openings/rd', icon: 'edit' },
    { action: 'FD', path: '/edit/openings/fd', icon: 'edit' }
]

export const VERIFICATION_DASHBOARD = [
    { action: 'Manager Verification', path: 'verification/dashboard/manager', icon: 'people' },
    { action: 'Cashier Verification', path: 'verification/dashboard/cashier', icon: 'payments' },
    { action: 'Denomination', path: 'verification/dashboard/denomination', icon: 'money' },
    { action: 'Edit Transactions', path: '/staff/dashboard/transactions/edit-transaction', icon: 'edit_note' },
    { action: 'Account Head', path: '/staff/dashboard/transactions/accounts/head', icon: 'add_business' },
    { action: 'Account Settings', path: '/staff/dashboard/transactions/accounts/settings', icon: 'manage_accounts' },
    { action: 'Loan Settings', path: '/staff/dashboard/transactions/loan/settings', icon: 'settings' },
    { action: 'Mds Settings', path: '/staff/dashboard/transactions/mds/settings', icon: 'settings' },
    { action: 'Issue Duplicate Certificate', path: '/manager/dashboard/duplicate-certificate', icon: 'description' }
]

export const LOCKER_DASHBOARD = [
    { label: 'Active', path: '', icon: 'no_encryption_gmailerrorred', iconStyle: 'icon-style-active', titleStyle: 'title-style-active', boxno: 0 },
    { label: 'Open', path: '/staff/dashboard/transactions/locker/dashboard/new', icon: 'lock_open', iconStyle: 'icon-style-open', titleStyle: 'title-style-open', boxno: 0 }
]