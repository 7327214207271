import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs'
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ShortcutsComponent } from './shared/shortcuts/shortcuts.component';
import { DataService } from './data.service';
import { AuthService } from './auth/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {

  update: boolean = false;

  title = 'Fin-Banking Solutions';

  constructor(
    private _updates: SwUpdate,
    private titleService: Title,
    private breadcrumbService: BreadcrumbService,
    private _router: Router,
    private _dialog: MatDialog,
    private _dataService: DataService,
    private _auth: AuthService,
    private _cookieService: CookieService
  ) {

    this._updates.available.subscribe(event => {
      this.update = true;
      _updates.activateUpdate().then(() => document.location.reload)
    })
  }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    })
    this.checkCookies()
    // this.setDashboardPrevilege()
  }

  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'Fin-Banking Solutions';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} ${title}`;
  }

  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName} - ${prev}`;
    }, '');
  }

  @HostListener('window:keydown.control.h', ['$event'])
  openHints(event: KeyboardEvent) {
    event.preventDefault();
    this._dialog.closeAll()
    const hintRef = this._dialog.open(ShortcutsComponent, {
      width: '400px',
      height: '500px',
    })

    hintRef.afterClosed().subscribe(result => { });
  }
  @HostListener('window:keydown.alt.c', ['$event'])
  openCustomerReg(event: KeyboardEvent) {
    event.preventDefault();
    this._router.navigate([]).then(result => { window.open('/reports/dashboard/registers/customer', '_blank'); });
  }
  @HostListener('window:keydown.alt.f', ['$event'])
  openFDSB(event: KeyboardEvent) {
    event.preventDefault();
    this._router.navigate([]).then(result => { window.open('/interest/dashboard/transfer/fdsb', '_blank'); });
  }
  @HostListener('window:keydown.alt.s', ['$event'])
  openSBRD(event: KeyboardEvent) {
    event.preventDefault();
    this._router.navigate([]).then(result => { window.open('/interest/dashboard/transfer/sbrd', '_blank'); });
  }
  @HostListener('window:keydown.alt.r', ['$event'])
  openReports(event: KeyboardEvent) {
    event.preventDefault();
    this._router.navigate([]).then(result => { window.open('/reports/dashboard', '_blank'); });
  }
  @HostListener('window:keydown.alt.d', ['$event'])
  openDaybook(event: KeyboardEvent) {
    event.preventDefault();
    this._router.navigate([]).then(result => { window.open('/reports/dashboard/daysummary/detailed-daybook', '_blank'); });
  }

  setDashboardPrevilege() {
    console.log('app.component.ts')
    this._dataService.dashPrevData$.subscribe(res => {
      if (!res.isValid) {
        this.fetchDashPrevileges()
        console.log('not set')
      }
    })
  }

  fetchDashPrevileges() {
    this._auth.getDefaultDashboard(this._cookieService.get('userid')).subscribe(response => {
      if (response['status'] && response['result'].length > 0) {
        console.log('setting')
        this._dataService.setDashPrevilegeDetails(true, response['result']);
      }
    })
  }

  checkCookies(){
    if (this._cookieService.get('userid') && this._cookieService.get('brid') && this._cookieService.get('usercode')) {
      this.setDashboardPrevilege()
    }
  }

}