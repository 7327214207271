import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSortModule } from '@angular/material/sort';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectFilterModule } from 'mat-select-filter';
import {DragDropModule} from '@angular/cdk/drag-drop';

const MaterialComponents = [
	CommonModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatToolbarModule,
	MatIconModule,
	MatSidenavModule,
	MatBadgeModule,
	MatListModule,
	MatGridListModule,
	MatFormFieldModule,
	MatInputModule,
	MatSelectModule,
	MatRadioModule,
	MatDatepickerModule,
	MatChipsModule,
	MatTooltipModule,
	MatTableModule,
	MatPaginatorModule,
	MatCardModule,
	MatNativeDateModule,
	MatCheckboxModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MatTabsModule,
	MatSnackBarModule,
	MatProgressSpinnerModule,
	MatSortModule,
	ClipboardModule,
	MatMenuModule,
	MatProgressBarModule,
	MatSlideToggleModule,
	DragDropModule,
	MatSelectFilterModule
];



@NgModule({
	imports: [MaterialComponents],
	exports: [MaterialComponents],
	providers: [
		MatDatepickerModule
	]
})
export class MaterialModule { }
