<div class="header">
    <mat-icon>keyboard</mat-icon>&nbsp;
    <h6><b>Keyboard Shortcuts</b></h6>
</div>
<mat-divider></mat-divider>
<div class="shortcuts-container">
    <div class="content" *ngFor="let shortcuts of KEYBOARD_SHORTCUTS">
        <div class="hint">
            <p>{{shortcuts.hint}}</p>
            <p class="remarks">{{shortcuts.remarks}}</p>
        </div>
        <div class="keys">
            <span class="btn" *ngFor="let key of getKeys(shortcuts.command)">{{key}}</span>
        </div>
    </div>
</div>