import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { QuicklinkModule } from 'ngx-quicklink';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './_security/page-not-found/page-not-found.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { LayoutModule } from '@angular/cdk/layout';
import { ValidationService } from './_security/validation.service';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataService } from './data.service';
import { MaterialModule } from './material/material.module';
import { HttpErrorInterceptor } from "./_helpers/http-error.interceptor";
import { ErrorDialogComponent } from './_helpers/error-dialog/error-dialog.component';
import { NotFoundComponent } from './_helpers/not-found/not-found.component';
import { AuthInterceptor } from "./_helpers/auth.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ErrorDialogComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    QuicklinkModule,
    BreadcrumbModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    LayoutModule,
    MaterialModule,
    RxReactiveFormsModule,
    HttpClientModule

  ],
  providers: [
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
    BreadcrumbModule,
    ValidationService,
    DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptor, 
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
