import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './_security/auth.guard';

const routes: Routes = [
  {
    path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    data: {
      breadcrumb: 'Authentication'
    }
  },
  {
    path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    data: {
      breadcrumb: 'Reports'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'verification', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule),
    data: {
      breadcrumb: 'verification'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'views', loadChildren: () => import('./views/views.module').then(m => m.ViewsModule),
    data: {
      breadcrumb: 'Views'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'head-office', loadChildren: () => import('./head-office/head-office.module').then(m => m.HeadOfficeModule),
    data: {
      breadcrumb: 'Head Office'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'staff', loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule),
    data: {
      breadcrumb: 'Staff'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'manager', loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
    data: {
      breadcrumb: 'Manager'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'interest', loadChildren: () => import('./interest/interest.module').then(m => m.InterestModule),
    data: {
      // breadcrumb: 'Interest'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    data: {
      breadcrumb: 'Settings'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'verification', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule),
    data: {
      breadcrumb: 'Verification'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'edit', loadChildren: () => import('./edit/edit.module').then(m => m.EditModule),
    data: {
      breadcrumb: 'Edit'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'legal-actions', loadChildren: () => import('./legal-actions/legal-actions.module').then(m => m.LegalActionsModule),
    data: {
      breadcrumb: 'Legal-Actions'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'payroll', loadChildren: () => import('./payroll/payroll.module').then(m => m.PayrollModule),
    data: {
      breadcrumb: 'Payroll'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'fin', loadChildren: () => import('./fin/fin.module').then(m => m.FinModule),
    data: {
      breadcrumb: 'Fin'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes )],
  //, { preloadingStrategy: QuicklinkStrategy,useHash:true }
  exports: [RouterModule]
})
export class AppRoutingModule { }
