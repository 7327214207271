import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SharedService } from '../shared/shared.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private _registerUrl = "http://localhost:3000/auth/register";
  // private _loginUrl = "http://localhost:3000/auth/login";
  // private _dayOpenUrl = "http://localhost:3000/auth/dayOpen";

  constructor(private http: HttpClient,
    private _router: Router,
    private _shared: SharedService) { }

  loginUser(user) {
    const userdetails = {
      username: user.username,
      _password: user.password,
      captcha: user.captchaText
    }
    return this.http.post(environment._authAPI + '/login', userdetails)
  }

  checkDayOpenStatus(transdate) {
    let params = {
      transdate: transdate
    }
    return this.http.post(environment._authAPI + '/dayOpenStatus',params)
  }

  checkBranchOpen(branchid) {
    let params = {
      branchid: branchid
    }
    return this.http.post(environment._authAPI + '/branchOpenStatus', params)
  }

  checkPrevilege(prevName, userid) {
    const loginData = {
      previlege: prevName,
      userid: userid
    }
    return this.http.post(environment._authAPI + '/checkPrevilege', loginData)
  }

  fetchHolidays() {
    return this.http.get(environment._authAPI + '/fetchHolidays')
  }

  setDayOpeningStatus(dayFormData, holidayFormData, userid, brid, currentDate) {

    const dayOpenParams = {
      _date: this._shared.formatDate(currentDate),
      _time: this._shared.formatTime(currentDate),
      _userid: userid,
      _brid: brid,
      _holidayReason: holidayFormData.holidayReason,
      _holidayDesc: holidayFormData.holidayDesc,
      _bankRate: dayFormData.bankRate,
      _marketRate: dayFormData.marketRate,
      _specialRate: dayFormData.specialRate,
      _active: 'Y'
    }

    console.log(dayOpenParams)

    return this.http.post(environment._authAPI + '/setDayOpenStatus', { dayOpenParams: dayOpenParams })
  }

  setHoliday(holidayFormData, userid, brid, currentDate) {
    const Params = {
      _date: this._shared.formatDate(currentDate),
      _time: this._shared.formatTime(currentDate),
      _userid: userid,
      _brid: brid,
      _holidayReason: holidayFormData.holidayReason,
      _holidayDesc: holidayFormData.holidayDesc,
      _active: 'Y'
    }

    console.log(Params)

    return this.http.post(environment._authAPI + '/setHoliday', { Params: Params })
  }


  setBranchOpening(BranchFormData, branchid, opuserid) {

    const branchOpenParams = {
      BranchFormData: BranchFormData,
      transdate: this._shared.formatDate(BranchFormData.openingdate),
      branchid: branchid,
      userid: opuserid,
      opdate: this._shared.formatDate(new Date()),
      optime: this._shared.formatTime(new Date())
    }
    console.log(branchOpenParams)

    return this.http.post(environment._authAPI + '/setBranchOpenStatus', { branchOpenParams: branchOpenParams })
  }

  checkDayOpen() {
    return this.http.get(environment._authAPI + '/dayOpenStatus')
  }

  fetchNextDate() {
    return this.http.get(environment._authAPI + '/fetchNextDate')
  }

  fetchOpeningBranchDate(branchid) {
    const Params = {
      branchId: branchid,
    }
    return this.http.post(environment._authAPI + '/fetchNextBranchDate', Params)
  }

  fetchOpeningBalance(branchid) {

    const Params = {
      branchId: branchid,
    }
    return this.http.post(environment._authAPI + '/fetchOpeningBalance', Params)
  }

  getCaptcha() {
    return this.http.get(`${environment._authAPI}/captcha`);
  }

  logOutUser(userid, branchid) {
    return this.http.post(`${environment._authAPI}/logout`, { _id: userid, _bid: branchid })
  }

  closeDayAndBranch(closing, userid, branchid, closingdate) {

    const Params = {
      closing: closing,
      closingform: { date: closingdate },
      userid: userid,
      branchid: branchid
    }
    return this.http.post(environment._authAPI + '/closeDayAndBranch', Params)
  }

  fetchBankAndBranch(brid) {
    const param = {
      brid: brid
    }
    return this.http.post(environment._authAPI + '/fetchBankAndBranch', param)
  }

  getDefaultDashboard(userid) {
    const loginData = {
      userid: userid
    }
    return this.http.post(environment._authAPI + '/fetchDefaultDashboard', loginData)
  }

  async getDefaultDashboardGuard(userid) {
    const loginData = {
      userid: userid
    }
    return this.http.post(environment._authAPI + '/fetchDefaultDashboard', loginData).toPromise()
  }

  /* Source for accessing dashboard previlege details */
  private _dashPrevSource = new BehaviorSubject<any>({ isValid: false, dashPrevData: null });
  dashPrevData$ = this._dashPrevSource.asObservable();

  setDashPrevilegeDetails(formValid, dashPrevData) {
    this._dashPrevSource.next({ isValid: formValid, dashPrevData: dashPrevData })
  }
}
