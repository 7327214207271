import { Component, OnInit } from '@angular/core';
import { KEYBOARD_SHORTCUTS } from './shortcuts.models';

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class ShortcutsComponent implements OnInit {

  KEYBOARD_SHORTCUTS = KEYBOARD_SHORTCUTS;

  constructor() { }

  ngOnInit(): void {
  }
  getKeys(command: string) {
    return command.split(' + ')
  }
}
